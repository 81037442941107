<template>
  <div class="error-container no-select">
    <div class="error-box">
      <el-empty description="页面不存在" :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.error-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}

.error-box {
  padding-bottom: 150px;
}
</style>
